<template lang="pug">
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6 {{ $t('paymentProcessing.title') }}

		p.mb-4 {{ $t('paymentProcessing.text[0]') }}
		p.mb-4 {{ $t('paymentProcessing.text[1]') }}
			br
			| {{ $t('paymentProcessing.text[2]') }}
		p.mb-4 {{ $t('paymentProcessing.text[3]') }}
		p.mb-4 {{ $t('paymentProcessing.text[4]') }}
		p.mb-4 {{ $t('paymentProcessing.text[5]') }}

		hr.primary.my-6

		h2.mb-6 {{ $t('paymentProcessing.text[6]') }}

		p.mb-4 {{ $t('paymentProcessing.text[7]') }}
		p.mb-4 {{ $t('paymentProcessing.text[8]') }}
		p.mb-4 {{ $t('paymentProcessing.text[9]') }}
			ul
				li(v-for='i in ul') {{ i }}

		p.mb-4 {{ $t('paymentProcessing.text[10]') }}
		br
		br
		pw-btn-up
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyData from '@/components/Common/Company';
import PwBtnUp from '@/components/Buttons/PwBtnUp';
import { bus } from '@/main';

export default {
    metaInfo() {
        return { title: bus.$t('paymentProcessing.title') };
    },
    components: {
        CompanyData,
        PwBtnUp,
    },
    data() {
        return {};
    },
    computed: {
        ul() {
            return bus.$t('paymentProcessing.ul');
        },
    },
    created() {
        window.scrollTo(0, 0);

        this.viewGeoIpRussia();
    },
};
</script>
